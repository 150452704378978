<template>
    <div>
        <h2 class="title">On Call Users</h2>

       
        <div class="notification is-warning" v-if="loading == true">
            Loading users ...
        </div>
        
        <table class="table is-fullwidth is-hoverable" v-if="loading == false ">
            <thead>
                <tr>
                     <th>Forename</th>
                    <th>Surname</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.users" :key="row.id">
                    <td>{{ row.forename }}</td>
                    <td>{{ row.surname}}</td>
                   
                    
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'OnCall',
        mixins: [MemberMixin],
        components: {
            //MemberGradeTags
        },
        data: function () {
            return {
                   search: '',
                   users: [],
                   loading: false,
                  order_by: 'forename, surname ASC'
            }
        },
        methods: {
            async getUsers() {
                UtilityService.getSimpleApiData(this.accessToken, "users?no_sports=1&order_by=" + this.order_by).then(
                    response => this.users = response 
                );
                
            },
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getUsers(this.order_by)
            });
        
        },
    }
</script>